import { pcrTargetNames } from "@repo/data/datasets";
import { capitalize, mapValues } from "lodash";

import api, { type ApiOutput } from "src/api";

import type { DataFilterOptions } from "./types";

const niceDatasetName = (x: string) => {
  if (x.startsWith("*")) {
    return `All ${x.slice(1)} datasets`;
  } else {
    const parts = x.split(":");
    const suffix = parts.includes("interpolated") ? " (interpolated)" : "";
    return capitalize(parts[0]) + suffix;
  }
};

const transformFormOptions = (
  data: ApiOutput<"customer.form.getOptions">,
): DataFilterOptions => ({
  program_code: data.programs.map((x) => ({
    label: `${x.program_name} (${x.program_code})`,
    value: x.program_code,
  })),
  sampling_location_id: data.sampling_locations.map((x) => ({
    label: `${x.sampling_location_name} (${x.sampling_location_id})`,
    value: x.sampling_location_id,
  })),
  organization_display_id: data.organizations.map((x) => ({
    label: `${x.organization_name} (${x.organization_display_id})`,
    value: x.organization_display_id,
  })),
  county_fips: data.counties.map((x) => ({
    label: `${x.county_name} (${x.county_fips})`,
    value: x.county_fips,
  })),
  state_fips: data.states.map((x) => ({
    label: `${x.state_name} (${x.state_fips})`,
    value: x.state_fips,
  })),
  census_region: data.regions.map((x) => ({
    label: x.census_region,
    value: x.census_region,
  })),
  country_code: data.countries.map((x) => ({
    label: x.country_code,
    value: x.country_code,
  })),
  target_name: pcrTargetNames.map((target) => ({
    label: target,
    value: target,
  })),
  dataset_name: mapValues(data.datasets, (datasets) =>
    datasets
      .filter((x) => x.dataset_name !== "*kit") // all kit datasets is not allowed
      .map((x) => ({
        label: niceDatasetName(x.dataset_name),
        value: x.dataset_name,
      })),
  ),
});

const useCustomerFormOptions = () =>
  api.customer.form.getOptions.useQuery({}, { select: transformFormOptions });

export default useCustomerFormOptions;
