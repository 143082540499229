import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import type { Datasets, Links } from "./types";

interface DownloadCardProps<T extends Datasets> extends LinkComponentProps<T> {
  className?: string;
  title: string;
  singularTitle?: string;
  image: string;
  datasets: T;
}

export interface LinkComponentProps<T extends Datasets> {
  links?: Pick<Links, T[number]>;
  loading?: boolean;
}

export const useLinkState = <T extends Datasets>({
  links,
  loading,
  datasets,
}: LinkComponentProps<T> & { datasets: T }) => {
  if (loading) {
    return { state: "loading", links: null } as const;
  } else if (links && datasets.some((k) => links[k]?.href)) {
    return { state: "data", links } as const;
  } else {
    return { state: "no-data", links: null } as const;
  }
};

const DownloadCard = <T extends Datasets>({
  className,
  title,
  singularTitle,
  image,
  datasets,
  ...props
}: DownloadCardProps<T>) => {
  const { state, links } = useLinkState({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title={title}
        image={image}
        singularTitle={singularTitle}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          {datasets.map((k: T[number]) => {
            const info = links[k];
            if (!info?.href) {
              return null;
            }
            return (
              <ExportButton
                key={k}
                label={info.label}
                href={info.href}
                gaAction={`${k}-downloaded`}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default DownloadCard;
