import covidImage from "src/assets/images/covid_virus.png";

import DownloadCard, { type LinkComponentProps } from "./DownloadCard";

const datasets = [
  "covidNWSS",
  "covidCDC",
  "covidKits",
  "covidCountyInterpolated",
  "covidCountyAverage",
  "covidZipAverage",
  "covidZip3Average",
  "covidStateAverage",
  "covidRegionalAverage",
  "covidNationalAverage",
  "covidVariants",
] as const;

interface CovidDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const CovidDownloads = (props: CovidDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="SARS-CoV-2"
    image={covidImage}
  />
);

export default CovidDownloads;
