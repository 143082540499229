import mpoxImage from "src/assets/images/mpx_virus.png";

import DownloadCard, { type LinkComponentProps } from "./DownloadCard";

const datasets = ["mpoxNWSS"] as const;

interface MpoxDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const MpoxDownloads = (props: MpoxDownloadsProps) => (
  <DownloadCard {...props} datasets={datasets} title="Mpox" image={mpoxImage} />
);

export default MpoxDownloads;
