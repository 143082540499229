import { Form } from "antd";

import BigSelect from "src/components/BigSelect";
import DateStringPicker from "src/components/DateStringPicker";

import type { DataFilterComponent, DataFilterComponentProps } from "./types";
import {
  endDateRule,
  formGridClass,
  formSelectProps,
  targetNameHelp,
  targetNameRule,
} from "./utils";

const StateDataFilter: DataFilterComponent<"state"> = ({
  name,
  options,
}: DataFilterComponentProps) => (
  <div className={formGridClass}>
    <p className="leading-8">
      <span className="text-coral-5">* </span>Datasets
    </p>
    <Form.Item name={[...name, "dataset_name"]} rules={[{ required: true }]}>
      <BigSelect options={options.dataset_name.state} {...formSelectProps} />
    </Form.Item>
    <p className="leading-8">Target name {targetNameHelp}</p>
    <Form.Item
      name={[...name, "target_name"]}
      rules={[targetNameRule(name)]}
      dependencies={[["data_filter", ...name, "dataset_name"]]}
    >
      <BigSelect options={options.target_name} {...formSelectProps} />
    </Form.Item>
    <p className="leading-8">
      <span className="text-coral-5">* </span>States
    </p>
    <Form.Item name={[...name, "state_fips"]} rules={[{ required: true }]}>
      <BigSelect options={options.state_fips} {...formSelectProps} />
    </Form.Item>
    <p className="leading-8">Sample date start</p>
    <Form.Item name={[...name, "start_date"]}>
      <DateStringPicker />
    </Form.Item>
    <p className="leading-8">Sample date end</p>
    <Form.Item name={[...name, "end_date"]} rules={[endDateRule]}>
      <DateStringPicker />
    </Form.Item>
  </div>
);

StateDataFilter.defaultFilter = {
  filter_type: "state",
};

export default StateDataFilter;
