import api from "src/api";

import CovidDownloads from "./CovidDownloads";
import DataDictionariesCard from "./DataDictionariesCard";
import FluDownloads from "./FluDownloads";
import GeographiesCard from "./GeographiesCard";
import MpoxDownloads from "./MpoxDownloads";
import NoroDownloads from "./NoroDownloads";
import RsvDownloads from "./RsvDownloads";
import SubstancesDownloads from "./SubstancesDownloads";
import type { Links } from "./types";

interface DownloadHubPagePureProps {
  links?: Links;
  loading?: boolean;
}

export const DownloadHubPagePure = ({
  links,
  loading,
}: DownloadHubPagePureProps) => {
  const cardProps = { links, loading, className: "max-w-[280px]" };

  return (
    <div>
      <h1 className="text-h1">Download Hub</h1>
      <div className="text-h3 mb-6">
        Access customized wastewater surveillance data files for your research.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-16">
        <CovidDownloads {...cardProps} />
        <FluDownloads {...cardProps} />
        <RsvDownloads {...cardProps} />
        <NoroDownloads {...cardProps} />
        <MpoxDownloads {...cardProps} />
        <SubstancesDownloads {...cardProps} />
      </div>
      <DataDictionariesCard links={links} className="mt-8" />
      <GeographiesCard className="mt-8" />
    </div>
  );
};

// This should be less than the token expiration time
const linkTtlMs = 18 * 60 * 1000;

const DownloadHubPage = () => {
  // Note that isLoading is only true for the initial load/while cached data
  // exists (for a given query key), while isFetching is true also for
  // background re-fetches. Since the response includes tokens with expiration,
  // we want to put the UI in a loading state once the tokens are expired, so
  // that users are forced to wait to click a download link until we have fresh
  // unexpired tokens.
  const { data, isFetching } = api.export.links.useQuery(
    {},
    {
      staleTime: linkTtlMs,
      cacheTime: linkTtlMs,
      refetchInterval: linkTtlMs,
    },
  );
  return <DownloadHubPagePure links={data?.data} loading={isFetching} />;
};

export default DownloadHubPage;
