import fluImage from "src/assets/images/flu.png";

import DownloadCard, { type LinkComponentProps } from "./DownloadCard";

const datasets = [
  "fluKits",
  "fluCountyInterpolated",
  "fluCountyAverage",
  "fluZipAverage",
  "fluZip3Average",
  "fluStateAverage",
  "fluRegionalAverage",
  "fluNationalAverage",
] as const;

interface FluDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const FluDownloads = (props: FluDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="Influenza"
    image={fluImage}
  />
);

export default FluDownloads;
