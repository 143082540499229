import norovirusImage from "src/assets/images/norovirus.png";

import DownloadCard, { type LinkComponentProps } from "./DownloadCard";

const datasets = [
  "norovirusKits",
  "norovirusCountyAverage",
  "norovirusStateAverage",
  "norovirusRegionalAverage",
  "norovirusNationalAverage",
] as const;

interface NoroDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const NoroDownloads = (props: NoroDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="Norovirus"
    image={norovirusImage}
  />
);

export default NoroDownloads;
