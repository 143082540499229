import type { FlatRpcRoutes, RpcRoutes } from "~api/api/types";

import createRpcHooks from "./createRpcHooks";

interface MutationError {
  response: {
    data: {
      message: string;
    };
  };
}

export const getErrorMessage = (error: unknown) => {
  try {
    return (error as MutationError).response.data.message;
  } catch (_) {
    return "Unknown Error";
  }
};

const customerDashboardRoot = (() => {
  /* eslint-disable no-console */
  // We only allow backend switching in local and dev01 environments. In stg
  // and prod this entire `if` is compiled out.
  if (process.env.REACT_APP_CUSTOMER_DASHBOARD_BACKENDS) {
    const params = new URLSearchParams(window.location.search);
    const key =
      params.get("backend") ??
      sessionStorage.getItem("customer-dashboard-backend");
    if (key) {
      try {
        const backends = JSON.parse(
          process.env.REACT_APP_CUSTOMER_DASHBOARD_BACKENDS,
        );
        if (typeof backends === "object" && backends[key]) {
          console.log(`Using ${key} backend`);
          sessionStorage.setItem("customer-dashboard-backend", key);
          return backends[key] as string;
        }
      } catch (_err) {
        console.log("ERR", _err);
      }
    }
    const defaultBackend =
      process.env.REACT_APP_CUSTOMER_DASHBOARD_DEFAULT_BACKEND ?? "local";
    console.log(`Using default backend: ${defaultBackend}`);
    sessionStorage.setItem("customer-dashboard-backend", defaultBackend);
  }
  /* eslint-enable */
  return process.env.REACT_APP_CUSTOMER_DASHBOARD_ROOT!;
})();

/**
 * Backend api hooks.
 *
 * @see {@link createRpcHooks} for full usage.
 */
const api = createRpcHooks<RpcRoutes>({
  root: `${customerDashboardRoot}/rpc`,
  mutationOptions(path, opts) {
    if (path[0] === "customer" || path[0] === "featureFlag") {
      // Invalidate everything if a customer config changes, or if a feature
      // flag changes
      return {
        onSuccess(...successArgs) {
          opts.queryClient.invalidateQueries();
          opts.onSuccess?.(...successArgs);
        },
      };
    } else if (path[0]) {
      // Invalidate all models under the same top level path if something in
      // the model changes (e.g. invalidate all covid models when covid data is
      // released).
      return {
        onSuccess(...successArgs) {
          opts.queryClient.invalidateQueries([{ path: [path[0]] }]);
          opts.onSuccess?.(...successArgs);
        },
      };
    } else {
      return {};
    }
  },
});

/** The response output type for an api route. */
export type ApiOutput<Path extends keyof FlatRpcRoutes> =
  FlatRpcRoutes[Path] extends {
    output: infer T;
  }
    ? T
    : never;

/** The response output's { data } type for an api route. */
export type ApiData<Path extends keyof FlatRpcRoutes> =
  FlatRpcRoutes[Path] extends {
    output: { data: infer T };
  }
    ? T
    : never;

/** The request input type for an api route. */
export type ApiInput<Path extends keyof FlatRpcRoutes> =
  FlatRpcRoutes[Path] extends {
    input: infer T;
  }
    ? T
    : never;

export default api;
