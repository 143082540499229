import { pcrTargetByDataset } from "@repo/data/datasets";
import { FormRule } from "antd";
import dayjs from "dayjs";
import { camelCase, omitBy } from "lodash";

import type { IsoDate } from "~api/types/util";

import type { ApiData, ApiInput } from "src/api";
import { BigSelectProps } from "src/components/BigSelect";
import TooltipHelp from "src/components/TooltipHelp";

import type {
  CustomerFormData,
  DataFilter,
  DataFilterWithIsNew,
} from "./types";

export const normalizeDataFilter = (dataFilter: DataFilterWithIsNew[]) =>
  dataFilter.map(({ _isNew: _, ...f }) =>
    omitBy(f, (x) => x == null || (Array.isArray(x) && x.length === 0)),
  ) as DataFilter[];

export const emptyCustomerFormData: CustomerFormData = {
  customer_name: "",
  customer_code: "",
  data_filter: [],
  show_risk_scores: false,
  emails: [],
};

export const customerFormDataToPayload = ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter,
  emails,
}: CustomerFormData): ApiInput<"customer.create"> => ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter: data_filter && normalizeDataFilter(data_filter),
  emails,
});

export const customerPayloadToFormData = ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter,
}: ApiData<"customer.create">): CustomerFormData => ({
  customer_name,
  customer_code,
  show_risk_scores,
  data_filter,
  emails: [],
});

/**
 * Returns the single org, location, or program filter from an array of data
 * filters. This is used to compute the customer code.
 */
const onlyOrgLocationOrProgram = (dataFilter?: DataFilter[]) => {
  const options = (dataFilter ?? [])
    .map((f: Record<string, unknown>) => [
      f.program_code,
      f.organization_display_id,
      f.sampling_location_id,
    ])
    .flat(3)
    .filter(Boolean);
  return options.length === 1 && options[0];
};

export const generateCustomerCode = ({
  data_filter,
  customer_name,
}: Partial<CustomerFormData>) => {
  const orgLocationProgram = onlyOrgLocationOrProgram(data_filter);
  if (orgLocationProgram) {
    return orgLocationProgram.toString();
  } else if (customer_name) {
    return camelCase(customer_name).toLowerCase();
  } else {
    return "";
  }
};

const maxEndDate = dayjs().add(50, "years").format("YYYY-MM-DD");

export const endDateRule: FormRule = {
  validator(_, value: IsoDate) {
    if (value > maxEndDate) {
      return Promise.reject(
        new Error(
          "Please remove the end date instead of setting it to the distant future.",
        ),
      );
    } else {
      return Promise.resolve();
    }
  },
};

export const targetNameRule =
  (name: (string | number)[]): FormRule =>
  ({ getFieldValue }) => ({
    validator: () => {
      const { dataset_name, target_name } = getFieldValue([
        "data_filter",
        ...name,
      ]) as { dataset_name: string[]; target_name?: string[] };
      // Make sure that every dataset has a corresponding target
      const invalidDatasets = dataset_name.filter(
        (d) =>
          !pcrTargetByDataset[d.split(":")[0]]?.some((t) =>
            target_name?.includes(t),
          ),
      );
      if (!target_name?.length || invalidDatasets.length === 0) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          new Error(
            `The target_name filter will exclude some selected datasets. You should either (a) remove the target_name filter, (b) remove these datasets, or (c) add a target_name filter that will include every selected dataset. Invalid datasets: ${invalidDatasets.join(", ")}`,
          ),
        );
      }
    },
  });

export const formSelectProps: Partial<BigSelectProps> = {
  allowClear: true,
  maxTagCount: "responsive",
  className: "w-0 min-w-full",
};

export const formGridClass =
  "grid grid-cols-[auto,1fr] gap-2 [&_.ant-form-item]:mb-0";

export const targetNameHelp = (
  <TooltipHelp title="This should only be used for special cases where you want to limit the targets for a dataset (e.g. limiting the Flu dataset to InfB)." />
);
