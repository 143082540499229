import { Tag } from "antd";
import cx from "classnames";
import { uniq } from "lodash";

import type { DataFilterOptions } from "./types";

type AnyDataFilter = Record<string, unknown>;

interface DataFilterTagsProps {
  dataFilter?: AnyDataFilter;
  options?: DataFilterOptions;
  maxTags?: number;
}

const stdClasses =
  "border-solid border text-ellipsis overflow-hidden max-w-[10rem]";

// This also defines the order that tags appear in
const tagClass = {
  dataset_name: "bg-biobotblue-1 text-biobotblue-8 border-biobotblue-5",
  target_name: "bg-green-1 text-green-8 border-green-5",
  program_code: "bg-purple-1 text-purple-8 border-purple-5",
  organization_display_id: "bg-orange-1 text-orange-8 border-orange-5",
  sampling_location_id: "bg-green-1 text-green-8 border-green-5",
  county_fips: "bg-purple-1 text-purple-8 border-purple-5",
  state_fips: "bg-purple-1 text-purple-8 border-purple-5",
  census_region: "bg-purple-1 text-purple-8 border-purple-5",
  country_code: "bg-purple-1 text-purple-8 border-purple-5",
};

const DataFilterTags = ({
  dataFilter,
  options: options_,
  maxTags = 10,
}: DataFilterTagsProps) => {
  if (!dataFilter) {
    return null;
  }
  const options = {
    ...options_,
    dataset_name: Object.values(options_?.dataset_name ?? {}).flat(),
  };
  const optionsFor = (k: string) => {
    const val = dataFilter[k];
    const opts = options[k] as { label: string; value: unknown }[] | undefined;
    if (val == null) {
      return [];
    }
    const arrVal = Array.isArray(val) ? val : [val];
    return arrVal.map(
      (x) =>
        opts?.find((opt) => opt.value === x) ?? {
          label: `${k}: ${x}`,
          value: x,
        },
    );
  };

  const { filter_type: _, start_date, end_date, ...otherFilters } = dataFilter;
  const filterKeys = uniq([
    ...Object.keys(tagClass),
    ...Object.keys(otherFilters),
  ]);

  const tagData = filterKeys.flatMap((k) =>
    optionsFor(k).map((o, idx) => ({ ...o, key: k, hide: idx >= 3 })),
  );

  // special-case start and end date to make the tag easier to read
  if (start_date || end_date) {
    tagData.push({
      label: `${start_date || "unbounded"} - ${end_date || "unbounded"}`,
      value: `${start_date},${end_date}`,
      key: "date_range",
      hide: false,
    });
  }

  const tags = tagData
    .filter((x) => !x.hide || tagData.length <= maxTags)
    .map(({ value, label, key }) => (
      <Tag
        title={`${key}: ${value}`}
        key={`${key}: ${value}`}
        color={tagClass[key] ? "transparent" : undefined}
        className={cx(tagClass[key], stdClasses)}
      >
        {label}
      </Tag>
    ));
  if (tags.length < tagData.length) {
    tags.push(<Tag key="extra">... + {tagData.length - tags.length}</Tag>);
  }
  return <>{tags}</>;
};

export default DataFilterTags;
