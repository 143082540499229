import hrsImage from "src/assets/images/hrs_molecule.png";

import DownloadCard, { type LinkComponentProps } from "./DownloadCard";

const datasets = ["lcmsKits", "lcmsNationalAverage"] as const;

interface SubstancesDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const SubstancesDownloads = (props: SubstancesDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="Substances"
    singularTitle="Substance"
    image={hrsImage}
  />
);

export default SubstancesDownloads;
